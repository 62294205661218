.custom-input-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.custom-input-label {
  font-size: 12px;
  color: #555;
  position: absolute;
  top: -8px;
  left: 8px;
  background-color: white;
  padding: 0 5px;
  transition: 0.3s;
  pointer-events: none;
}

.custom-input-field {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: 0.3s;
  outline: none;
}

.custom-input-field:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 5px rgba(63, 81, 181, 0.5);
}

.focused .custom-input-label {
  color: #3f51b5;
}

.custom-input-field.error {
  border-color: red;
}

.custom-input-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
