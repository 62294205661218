.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 922px) {
  .container {
    height: auto;
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: aquamarine;
    align-items: center;
    flex-direction: column;
    gap: 60px;
  }
}
